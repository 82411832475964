<template>
  <div>
         <div class="search">
        <div class="title">售后列表</div>
        <hr />
      <el-form :inline="true"  class="top">
        <el-form-item label="订单号:">
            <el-input v-model="params.coding" ></el-input>
        </el-form-item>
        <el-form-item label="手机号:">
            <el-input v-model="params.phone" ></el-input>
        </el-form-item>
        <el-form-item label="售后类型:">
           <el-select v-model="params.backType" placeholder="请选择">
                <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
                </el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="状态:">
             <el-select v-model="params.status" placeholder="请选择">
                <el-option
                v-for="item in options2"
                :key="item.value"
                :label="item.label"
                :value="item.value">
                </el-option>
            </el-select>
        </el-form-item>
            <!-- <el-form-item label="时间:">
                 <el-date-picker
                    :editable='false'
                    :picker-options="pickerOptions0"
                    value-format='yyyy-MM-dd HH:mm:ss'
                    v-model="date"
                    type="datetimerange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期">
                </el-date-picker>
             </el-form-item> -->
         <el-form-item >
            <el-button type="primary"  @click="getList">查询</el-button>
            <el-button type="primary"   @click="reset">重置</el-button>
        </el-form-item>
      
        </el-form>
     </div>
        <el-table
        :data="tableData"
        border
        style="width: 100%"
        align="center">
         <!-- <el-table-column type="selection" width="55"  align="center"> </el-table-column> -->
        <el-table-column type="index" width="50"   align="center"> </el-table-column>
        <el-table-column
        prop="coding"
        label="订单号"
        
        align="center">
        </el-table-column>
       
       
         <el-table-column
        prop="userInfo"
        label="申请人"
        width="200"
        align="center">
        </el-table-column>
         <el-table-column
        prop="strType"
        label="申请类型"
         width="180"
        align="center">
        </el-table-column>
         <el-table-column
        prop="reason"
        label="申请原因"
         width="180"
        align="center">
        </el-table-column>
         <el-table-column
        prop="backFee"
        label="退款金额"
         width="180"
        align="center">
        </el-table-column>
         <el-table-column
        prop="strStatus"
        label="当前状态"
         width="180"
        align="center">
        </el-table-column>
         <el-table-column
        prop="applyTime"
        label="申请时间"
        width="200"
        align="center">
        </el-table-column>
        <el-table-column
        label="操作"
        align="center"
        >
        <template slot-scope="scope">
            <el-button type="primary"  size="mini" @click="toDetails(scope.row)">查看详情</el-button>

        </template>
        </el-table-column>
    </el-table>
         <div class="block">
         <!-- <div>
              <el-button type="primary" @click="allCheckVip">批量修改vip</el-button>
            <el-button type="primary" @click="allOpe">批量操作</el-button>
         </div> -->
        <div>
              <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="params.currentPage"
            :page-sizes="[5,10,20]"
            :page-size="params.pageSize"
            layout="total,sizes,prev,pager,next, jumper"
            :total="total"
        >
        </el-pagination>
        </div>
     </div>
  </div>
</template>

<script>
import { getAfterList } from '../../api/selectMall.js'
export default {
    data() {
        return {
            total: 0,
            params: {
                currentPage: 1,
                pageSize: 5,
                coding: '',
                phone: '',
                // beginTime: '',
                // endTime: '',
                backType: 0,
                status:'',
            },
            options:[
                {
                    value:0,
                    label:'全部'
                },
                {
                    value:1,
                    label:'仅退款'
                },
                {
                    value:2,
                    label:'退货退款'
                }
            ],
            options2:[
               
                {
                    value:1,
                    label:'会员提交申请'
                },
                {
                    value:2,
                    label:'等待商家录入退货地址'
                },
                {
                    value:3,
                    label:'商家拒绝申请'
                },
                {
                    value:4,
                    label:'等待用户输入物流信息'
                },
                {
                    value:5,
                    label:'等待商家收货'
                },
                {
                    value:6,
                    label:'等待商家打款'
                },
                {
                    value:7,
                    label:'已完成'
                },
                {
                    value:8,
                    label:'用户撤销'
                }
            ],
            tableData: [],
            date: null,
            pickerOptions0: {
            disabledDate(time) {
                return time.getTime() > Date.now() - 8.64e6
            },
         }
        }
    },
    created(){
        this.getList()
    },
    methods: {
        async getList(){
            // this.params.beginTime = this.date ?  this.date[0] : ''
            // this.params.endTime = this.date ? this.date[1] : ''
            let params = JSON.parse(JSON.stringify(this.params)) //深拷贝
            params.status = params.status ? params.status : 0
            const {data} = await getAfterList(params)
            if(data.code == 0){
                this.tableData = data.list
                this.total = data.pagination.total
            }else{
                this.$message.warning(data.msg)
            }
        },
          handleSizeChange(e){
            this.params.pageSize = e
            this.getList()
        },
          // 页数改变
        handleCurrentChange(e){
            this.params.currentPage = e
            this.getList()
        },
        reset(){
          
            this.params.currentPage = 1
            this.params.pageSize = 5
            this.params.phone = ''
            this.params.coding = ''
            this.params.backType = ''
            this.params.status = ''
            // this.date = null
        },
        toDetails(row){
            this.$router.push({
                path: '/afterShopDetails',
                query: {
                    id: row.prodBackId
                }
            })
        
        }
    }
}
</script>

<style scoped>
.title {
  padding: 10px 0;
}
.top{
    margin-top: 20px;
}
.block{
    margin-top: 15px;
    display: flex;
    justify-content: flex-end;
    width: 100%;
}
</style>